import { render, staticRenderFns } from "./create-judges-component.vue?vue&type=template&id=326b35b8&scoped=true&"
import script from "./create-judges-component.vue?vue&type=script&lang=js&"
export * from "./create-judges-component.vue?vue&type=script&lang=js&"
import style0 from "./create-judges-component.vue?vue&type=style&index=0&id=326b35b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.5_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_vue-template-_is3gtokgxivt5vyoahwc5re7cy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "326b35b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/.pnpm/vuetify-loader@1.6.0_vue-template-compiler@2.6.12_vuetify@2.5.0_webpack@4.44.2/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VChip,VCol,VDialog,VDivider,VIcon,VListItem,VListItemContent,VListItemTitle,VRow})
