<template>
  <div>
    <svod-info-comonent v-if="festival" :festival="festival"></svod-info-comonent>
    <festival-view-tabs-component></festival-view-tabs-component>
    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" md="6" class="mb-3 mb-md-0">
        <v-btn :loading="export_protocols.loading" :disabled="export_protocols.loading" @click="exportProtocols"
               outlined text color="primary">Скачать итоговые протоколы
        </v-btn>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск по испытанию"
            dense
            @keyup="search"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >

          <template v-slot:item.actions="{ item }">
            <div class="table-actions d-flex">
              <v-icon
                  class="mr-2"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <remove-item ref="remover" :populate-with="selected" :type="'template'" @remove-item-success="removed"></remove-item>
  </div>
</template>
<script>
import debounce from 'debounce'

import {mapState, mapActions} from 'vuex';
import {api} from '@/api';
import RemoveItem from '@/components/modals/remove-item';
import FestivalViewTabsComponent from '@/views/Festival/components/festival-view-tabs-component';
import SvodInfoComonent from '@/views/Festival/components/svod-info-comonent';

export default {
  name: 'FestivalProtocolIndex',
  components: {
    SvodInfoComonent,
    FestivalViewTabsComponent,
    RemoveItem
  },
  data() {
    return {
      form: {
        search: null,
        onlyMine: false,
        loading: false,
        role: {
          selected: 'all'
        }
      },
      table: {
        headers: [
          {
            text: 'ID',
            align: 'start',
            value: 'exercise.id',
            width: '7%'
          },
          {text: 'Испытание', value: 'exercise.title_ru'},
          {text: '', value: 'actions'},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      festival: null,
      selected: null,
      export_protocols: {
        loading: null,
        batch_id: null,
        check_interval: null
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    domain(){
      return window.location.origin
    },
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),

    async fetch() {
      try {
        this.form.loading = true;
        let response = await api.festival.protocols.all(this.$route.params.id, {
          search: this.form.search,
          role: this.form.role.selected,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.table.list = response.data.list;
        this.table.meta = response.data.meta;
        this.festival = response.data.festival;
        this.form.loading = false;
      } catch (e) {
        this.error(e)
      }
    },
    async exportProtocols() {
      try {
        this.export_protocols.loading = true;
        let response = await api.festival.protocols.export.get(this.festival.id)
        this.export_protocols.batch_id = response.data
        this.export_protocols.check_interval = setInterval(() => {
          this.exportProtocolsChecker();
        }, 10000);
      } catch (e) {
        this.export_protocols.loading = false;
        this.export_protocols.batch_id = null;
        this.error(e)
      }
    },
    async exportProtocolsChecker() {
      try {
        let response = await api.festival.protocols.export.status.get(this.festival.id, {batch_id: this.export_protocols.batch_id});
        if (response.data && response.data.status === 'completed') {
          this.export_protocols.batch_id = null;
          this.export_protocols.loading = false;
          clearInterval(this.export_protocols.check_interval)
          window.open(response.data.file, '_blank');
        }
      } catch (e) {
        this.error(e)
      }
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated() {
      this.fetch()
    },
    editItem(item) {
      this.$router.push({
        name: 'festivals.protocols.show',
        params: {id: this.$route.params.id, exercise: item.exercise.id}
      })
    },
    removed(){
      this.fetch();
      this.notify('Шаблон удален')
    },

  },
}
</script>

<style scoped>

</style>
