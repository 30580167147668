<template>
  <div>
    <svod-info-comonent v-if="festival" :festival="festival"></svod-info-comonent>
    <festival-view-tabs-component></festival-view-tabs-component>

    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" md="6" class="mb-3 mb-md-0">
        <v-btn
            color="primary"
            @click="createScreen()"
            :disabled="!festival"
        >
          Добавить ссылку
        </v-btn>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск по названию"
            dense
            @keyup="search"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="table.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template v-slot:item.link="{ item }">
            <v-icon @click="goToScreen(item)" class="mr-3">mdi-open-in-new</v-icon>
            <v-icon @click="copyLink(item)">mdi-content-copy</v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="table-actions d-flex">
              <v-icon
                  class="mr-2"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <create-edit-screen-component ref="createForm" :festival="festival" :populate-with="selected"
                                  @saved="saved()"></create-edit-screen-component>

    <remove-item ref="remover" :populate-with="selected" :type="'screen'" @remove-item-success="removed"></remove-item>
  </div>
</template>
<script>
import debounce from 'debounce'

import {mapState, mapActions} from 'vuex';
import {api} from '@/api';
import RemoveItem from '@/components/modals/remove-item';
import FestivalViewTabsComponent from '@/views/Festival/components/festival-view-tabs-component';
import CreateEditScreenComponent from '@/views/Festival/components/screens/create-edit-screen-component';
import SvodInfoComonent from '@/views/Festival/components/svod-info-comonent';

export default {
  name: 'FestivalScreens',
  components: {
    SvodInfoComonent,
    CreateEditScreenComponent,
    FestivalViewTabsComponent,
    RemoveItem
  },
  data() {
    return {
      form: {
        search: null,
      },
      table: {
        loading: false,
        headers: [
          {
            text: 'Название',
            align: 'start',
            value: 'title',
          },
          {text: 'Ссылка', value: 'link', sortable: false,},
          {text: '', value: 'actions', width: '10%', sortable: false},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      selected: null,
      festival: null,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),

    async fetch() {
      try {
        this.table.loading = true;
        let response = await api.festival.screens.all( this.$route.params.id, {
          search: this.form.search,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });
        this.festival = response.data.festival;
        this.table.list = response.data.list
        this.table.meta = response.data.meta
        this.table.loading = false;
      } catch (e) {
        this.error(e);
      }
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated(){
      this.fetch()
    },
    editItem(item){
      this.selected = JSON.parse(JSON.stringify(item));
      this.$refs.createForm.dialog = true;
    },
    deleteItem(item){
      this.selected = item;
      this.selected.festival = this.festival;
      this.$refs.remover.dialog = true;
    },
    createScreen(){
      this.selected = null;
      this.$refs.createForm.resetForm();
      this.$refs.createForm.dialog = true;

    },
    removed(){
      this.fetch();
      this.notify('Ссылка удалена')
    },
    saved(){
      this.selected = null;
      this.$refs.createForm.dialog = false;
      this.fetch();
    },
    goToScreen(item){
      window.open(item.link, '_blank').focus();
    },
    copyLink(item){
      const el = document.createElement('textarea');
      el.value = item.link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.notify('Ссылка скопирована')
    }
  },
}
</script>

<style scoped>

</style>
