var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0"},[_c('h5',{staticClass:"text-h6 pb-0"},[_vm._v("О фестивале")])])],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.domainMask),expression:"domainMask"}],staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","label":"Ссылка на страницу фестиваля","placeholder":_vm.domain,"error-messages":errors,"dense":"","hide-details":""},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.languages,"filled":"","item-text":"text","item-value":"id","label":"Язык сайта фестиваля","multiple":"","hide-details":"","dense":"","error-messages":errors},model:{value:(_vm.form.languages),callback:function ($$v) {_vm.$set(_vm.form, "languages", $$v)},expression:"form.languages"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-divider')],1)],1),_c('v-row',{staticClass:"pa-3 pb-0 mt-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"название фестиваля","rules":_vm.isRuLanguageSelected ? 'required|max:400' : null,"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","label":"Название фестиваля","counter":"400","dense":"","error-messages":errors,"disabled":!_vm.isRuLanguageSelected},model:{value:(_vm.form.title_ru),callback:function ($$v) {_vm.$set(_vm.form, "title_ru", $$v)},expression:"form.title_ru"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"название фестиваля (en)","rules":_vm.isEnLanguageSelected ? 'required|max:400' : null,"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","search":"","label":"Название фестиваля (EN)","dense":"","counter":"400","error-messages":errors,"disabled":!_vm.isEnLanguageSelected},model:{value:(_vm.form.title_en),callback:function ($$v) {_vm.$set(_vm.form, "title_en", $$v)},expression:"form.title_en"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"короткое название","rules":_vm.isRuLanguageSelected ? 'max:255' : null,"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","label":"Короткое название","dense":"","counter":"255","error-messages":errors,"disabled":!_vm.isRuLanguageSelected},model:{value:(_vm.form.short_title_ru),callback:function ($$v) {_vm.$set(_vm.form, "short_title_ru", $$v)},expression:"form.short_title_ru"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"короткое название (EN)","rules":_vm.isEnLanguageSelected ? 'max:255' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","search":"","label":"Короткое название (EN)","dense":"","error-messages":errors,"counter":"255","disabled":!_vm.isEnLanguageSelected},model:{value:(_vm.form.short_title_en),callback:function ($$v) {_vm.$set(_vm.form, "short_title_en", $$v)},expression:"form.short_title_en"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","label":"Описание фестиваля","dense":"","hide-details":"","error-messages":errors,"disabled":!_vm.isRuLanguageSelected},model:{value:(_vm.form.description_ru),callback:function ($$v) {_vm.$set(_vm.form, "description_ru", $$v)},expression:"form.description_ru"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"color":"primary","filled":"","search":"","label":"Описание фестиваля (EN)","dense":"","hide-details":"","error-messages":errors,"disabled":!_vm.isEnLanguageSelected},model:{value:(_vm.form.description_en),callback:function ($$v) {_vm.$set(_vm.form, "description_en", $$v)},expression:"form.description_en"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-divider')],1)],1),_c('v-row',{staticClass:"pa-3 pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+# (###) ###-##-##'),expression:"'+# (###) ###-##-##'"}],staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","label":"Контактный телефон","dense":"","hide-details":"","error-messages":errors},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"email","skip-if-empty":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","filled":"","search":"","label":"Контактный e-mail","dense":"","error-messages":errors},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])})],1)],1),_c('v-row',{staticClass:"pa-3"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-md-2",attrs:{"label":"Дата открытия","prepend-inner-icon":"mdi-calendar","prepend-icon":"","filled":"","dense":"","hide-details":"","disabled":(_vm.festival.is_active || _vm.festival.is_finished)},model:{value:(_vm.formattedOpeningDate),callback:function ($$v) {_vm.formattedOpeningDate=$$v},expression:"formattedOpeningDate"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"error-messages":errors,"allowed-dates":_vm.disablePastDates},model:{value:(_vm.form.opening_date),callback:function ($$v) {_vm.$set(_vm.form, "opening_date", $$v)},expression:"form.opening_date"}})],1)]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","skip-if-empty":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата закрытия","prepend-inner-icon":"mdi-calendar","prepend-icon":"","filled":"","dense":"","hide-details":"","disabled":!_vm.form.opening_date || (_vm.festival.is_active || _vm.festival.is_finished)},model:{value:(_vm.formattedEndingDate),callback:function ($$v) {_vm.formattedEndingDate=$$v},expression:"formattedEndingDate"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"error-messages":errors,"allowed-dates":_vm.disablePastDatesBefore},model:{value:(_vm.form.ending_date),callback:function ($$v) {_vm.$set(_vm.form, "ending_date", $$v)},expression:"form.ending_date"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }