<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="720px"
    >
      <v-card class="mx-auto">
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
        >
          <v-card-title>
            Добавить судей
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
              >
                <ValidationProvider
                  name="Пользователь"
                  rules="required"
                  v-slot="{ errors }"
                  ref="usersProvider"
                >

                  <v-autocomplete
                    v-model="form.judges"
                    :items="users.list"
                    label="Поиск по Фио, УИН или email"
                    placeholder="Начните писать текст"
                    filled
                    :loading="users.loading"
                    item-value="id"
                    item-text="name"
                    return-object
                    hide-details
                    dense
                    multiple
                    chips
                    deletable-chips
                    :error-messages="errors"
                    :readonly="loading"
                    :filter="search"
                    :search-input.sync="userSearchString"
                  >
                    <v-icon
                      slot="prepend-inner"
                    >
                      mdi-magnify
                    </v-icon>

                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelectAll"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ !allSelected ? 'Выбрать всех' : 'Отменить выбор всех' }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>


                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                        class="mt-1 ml-0 mr-1 mb-1"
                        small
                      >
                        {{
                          `${data.item.profile.surname} ${data.item.profile.name} ${data.item.profile.middlename || ''}`
                        }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-content>
                        <div class="item-content">
                          <p><strong>{{
                              `${data.item.profile.surname} ${data.item.profile.name} ${data.item.profile.middlename || ''}`
                            }}</strong></p>

                          <p>ID: {{ data.item.id }} УИН: {{ data.item.profile.id }} <span v-if="data.item.email">Email: {{
                              data.item.email
                            }}</span></p>
                          <p v-if="data.item.already_in_current_festival">Уже в списке судей этого фестиваля</p>
                          <div v-if="data.item.busy_on_festival">
                            <strong>Добавление невозможно.</strong>
                            <p class="pb-0 mb-0">Данный пользователь уже назначен в роли судьи на другой фестиваль:</p>
                            <p class="pb-0 mb-0">ID {{ data.item.busy_on_festival.id }} - {{
                                data.item.busy_on_festival.title_ru ? data.item.busy_on_festival.title_ru : data.item.busy_on_festival.title_en
                              }}</p>
                          </div>
                        </div>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>

          </v-card-text>


          <v-card-actions class="pa-4">
            <v-btn
              color="primary"
              :disabled="invalid || loading"
              @click="submit()"
              :loading="loading"
            >
              Добавить
            </v-btn>

            <v-btn
              color="primary"
              outlined
              text
              @click="dialog = false"
              v-show="!loading"
            >
              Отменить
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');
import {mapState, mapActions} from 'vuex';

import {api} from '@/api';

export default {
  name: 'create-judges-component-user',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    dialog: false,
    loading: false,
    userSearchString: '',
    regionSearchString: '',
    municipalSearchString: '',
    form: {
      judges: []
    },
    users: {
      list: [],
      selected: null,
      loading: false
    },
  }),
  created() {
    this.getUsers();
  },
  watch: {
    'form.judges'(judgesNew, judgesOld) {
      if (judgesNew.length > judgesOld.length) {
        this.userSearchString = ''
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    allSelected(){
      return this.form.judges.length === this.users.list.filter(x => !x.disabled).length
    }
  },
  methods: {
    ...mapActions('app', ['notify']),
    getUsers() {
      this.users.loading = true;
      api.festival.judges.search(this.$route.params.id).then((response) => {
        this.users.list = [...response.data.list.data, ...this.form.judges];
        this.users.list.sort((a, b) => {
          return a.profile.surname.localeCompare(b.profile.surname);
        }).sort((a, b) => a.disabled - b.disabled)
        this.users.loading = false;
      })
    },
    async submit() {
      this.loading = true;
      try {
        await api.festival.judges.store(this.$route.params.id, {judges: this.form.judges});
        this.form.judges = [];
        this.$emit('submitted');
        this.dialog = false;
        this.getUsers();
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    remove(item) {
      const index = this.form.judges.findIndex(x => x.id === item.id)
      if (index >= 0) this.form.judges.splice(index, 1)
    },
    search(item, queryText) {
      let searchString = [item.profile.surname, item.profile.name, item.profile.middlename, item.profile.id,item.email].join(' ');
      console.log(searchString, queryText)
      return searchString.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    toggleSelectAll() {
      if (this.allSelected){
        this.form.judges = []
      } else {
        this.users.list.forEach(item => {
          if (!item.disabled && !this.form.judges.find(x => x.id === item.id)){
            this.form.judges.push(item)
          }
        })
      }

      const provider = this.$refs['usersProvider']
      provider.validate()
    }
  }
}
</script>

<style scoped>
.item-content {
  max-width: 656px;


  p {
    padding: 0;
    margin: 0 0 3px 0;
    font-size: 14px;
  }
}
</style>
