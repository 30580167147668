<template>
  <div class="mb-6">
    <v-row class="pa-3 pb-0">
      <v-col class="pb-0">
        <h5 class="text-h6 pb-0">О фестивале</h5>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
        <v-text-field
            color="primary"
            class="mr-md-2"
            v-model="form.link"
            filled
            label="Ссылка на страницу фестиваля"
            :placeholder="domain"
            :error-messages="errors"
            dense
            v-mask="domainMask"
            hide-details
        >
        </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >
          <v-select
              :items="languages"
              filled
              v-model="form.languages"
              item-text="text"
              item-value="id"
              label="Язык сайта фестиваля"
              multiple
              hide-details
              dense
              :error-messages="errors"
          >
          </v-select>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="pa-3 pb-0">
      <v-col cols="12" md="8">
        <v-divider></v-divider>
      </v-col>
    </v-row>


    <v-row class="pa-3 pb-0 mt-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            name="название фестиваля"
            :rules="isRuLanguageSelected ? 'required|max:400' : null"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              class="mr-md-2"
              v-model="form.title_ru"
              filled
              search
              label="Название фестиваля"
              counter="400"
              dense
              :error-messages="errors"
              :disabled="!isRuLanguageSelected"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            name="название фестиваля (en)"
            :rules="isEnLanguageSelected ? 'required|max:400' : null"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              v-model="form.title_en"
              filled
              search
              label="Название фестиваля (EN)"
              dense
              counter="400"
              :error-messages="errors"
              :disabled="!isEnLanguageSelected"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            name="короткое название"
            :rules="isRuLanguageSelected ? 'max:255' : null"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              class="mr-md-2"
              v-model="form.short_title_ru"
              filled
              search
              label="Короткое название"
              dense
              counter="255"
              :error-messages="errors"
              :disabled="!isRuLanguageSelected"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            name="короткое название (EN)"
            :rules="isEnLanguageSelected ? 'max:255' : null"
            v-slot="{ errors }"
        >
          <v-text-field
              color="primary"
              v-model="form.short_title_en"
              filled
              search
              label="Короткое название (EN)"
              dense
              :error-messages="errors"
              counter="255"
              :disabled="!isEnLanguageSelected"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            v-slot="{ errors }"
        >
          <v-textarea
              color="primary"
              class="mr-md-2"
              v-model="form.description_ru"
              filled
              search
              label="Описание фестиваля"
              dense
              hide-details
              :error-messages="errors"
              :disabled="!isRuLanguageSelected"
          >
          </v-textarea>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-textarea
              color="primary"
              v-model="form.description_en"
              filled
              search
              label="Описание фестиваля (EN)"
              dense
              hide-details
              :error-messages="errors"
              :disabled="!isEnLanguageSelected"
          >
          </v-textarea>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" md="8">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              class="mr-md-2"
              v-model="form.phone"
              filled
              search
              label="Контактный телефон"
              dense
              hide-details
              v-mask="'+# (###) ###-##-##'"
              :error-messages="errors"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            rules="email"
            v-slot="{ errors }"
            :skip-if-empty="true"
        >
          <v-text-field
              color="primary"
              v-model="form.email"
              filled
              search
              label="Контактный e-mail"
              dense
              :error-messages="errors"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >  <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedOpeningDate"
                label="Дата открытия"
                class="mr-md-2"
                prepend-inner-icon="mdi-calendar"
                prepend-icon=""
                filled
                v-bind="attrs"
                v-on="on"
                dense
                hide-details
                :disabled="(festival.is_active || festival.is_finished)"
            ></v-text-field>
          </template>
            <v-date-picker
                v-model="form.opening_date"
                :error-messages="errors"
                :allowed-dates="disablePastDates"
            ></v-date-picker>
          </v-menu>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >  <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedEndingDate"
                label="Дата закрытия"
                prepend-inner-icon="mdi-calendar"
                prepend-icon=""
                filled
                v-bind="attrs"
                v-on="on"
                dense
                hide-details
                :disabled="!form.opening_date || (festival.is_active || festival.is_finished)"
            ></v-text-field>
          </template>
            <v-date-picker
                v-model="form.ending_date"
                :error-messages="errors"
                :allowed-dates="disablePastDatesBefore"
            ></v-date-picker>
          </v-menu>
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ValidationProvider} from 'vee-validate';

export default {
name: 'festival-about-form-component',
  components: { ValidationProvider },
  props: {
    festival:{
      type: Object,
      default: function (){
        return {
          is_active: false,
          is_finished: false,
        }
      }
    },
    formData: {
      type: Object,
      default: function (){
        return {
          link: null,
          languages: ['ru', 'en'],
          title_ru: null,
          title_en: null,
          short_title_ru: null,
          short_title_en: null,
          description_ru: null,
          description_en: null,
          opening_date: null,
          ending_date: null,
          phone: null,
          email: null
        }
      }
    }
  },
  data(){
    return {
      languages: [{id: 'ru', text: 'Русский'}, {id: 'en', text: 'Английский'}],
      form: this.formData
    }
  },
  computed: {
    domain(){
      return process.env.VUE_APP_FRONT_URL
    },
    domainMask(){
      return this.domain + 'NNNNNNNNNNNNNNNNNNNNN';
    },
    isRuLanguageSelected() {
      return this.form.languages.includes('ru');
    },
    isEnLanguageSelected() {
      return this.form.languages.includes('en');
    },
    isAllLanguagesSelected() {
      return this.form.languages.length === this.languages.length
    },
    formattedOpeningDate() {
      return this.formatDate(this.form.opening_date)
    },
    formattedEndingDate() {
      return this.formatDate(this.form.ending_date)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10)
    },
    disablePastDatesBefore(val) {
      return val >= new Date(this.form.opening_date).toISOString().substr(0, 10)
    },
  }
}
</script>

<style scoped>

</style>
