var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"loginOverlay",attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"flex":"","align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","elevation-6":""}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('v-img',{attrs:{"contain":"","max-height":"95","max-width":"206","src":"/images/logo-wide.svg"}})],1),_c('v-card-text',{staticClass:"pt-4 pt-0"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form"},[(_vm.error)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"filled":"","dense":"","immediate":"","readonly":_vm.is_loading},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"пароль","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Пароль","error-messages":errors,"append-icon":!_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":!_vm.showPass ? 'password' : 'text',"counter":"","filled":"","dense":"","immediate":"","readonly":_vm.is_loading},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":invalid || _vm.is_loading,"loading":_vm.is_loading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v(" Войти ")]),_c('router-link',{attrs:{"to":{name: 'auth.reset-password', query: { email: _vm.form.email }}}},[_vm._v("Забыли пароль?")])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }