<template>
  <v-container fill-height class="loginOverlay">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm6 md4 elevation-6>
        <v-card>
          <v-card-title class="pb-0">
            <v-img
                contain
                max-height="95"
                max-width="206"
                src="/images/logo-wide.svg"
            ></v-img>
          </v-card-title>
          <v-card-text class="pt-4 pt-0">
            <ValidationObserver
                ref="observer"
                v-slot="{ invalid, handleSubmit }"
            >
              <v-form ref="form">
                <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="error"
                >
                  {{ error }}
                </v-alert>

                <ValidationProvider
                    name="e-mail"
                    rules="required|email"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-text-field
                      label="Email"
                      :error-messages="errors"
                      v-model="form.email"
                      filled
                      dense
                      immediate
                      :readonly="is_loading"
                  ></v-text-field>
                </ValidationProvider>

                <p>Введите эл. адрес для сброса пароля</p>

                <v-layout justify-space-between>
                  <v-btn
                      @click.prevent="handleSubmit(submit)"
                      class="primary"
                      :disabled="invalid || is_loading"
                      :loading="is_loading"
                  >
                    Восстановить
                  </v-btn>

                  <v-btn
                    :to="{ name: 'auth.login' }"
                  >
                    Отмена
                  </v-btn>

                </v-layout>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>


    <v-dialog
      v-model="dialog"
      width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Восстановление пароля
        </v-card-title>
        <v-card-text>
            На электронный адресс <strong>{{ form.email }}</strong> отправлены инструкции по сбросу пароля.
        </v-card-text>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate';
import {api} from '@/api';

setInteractionMode('eager');


export default {
  name: 'ResetPassword',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {
        email: null,
      },
      error: null,
      is_loading: false,
      dialog: false
    };
  },
  created(){
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
    }
  },
  computed: {
  },
  methods: {
    async submit() {
      try {
        await api.auth.passwordReset({ email: this.form.email})
        this.dialog = true
      } catch (e) {
        if (e.response.data.error === 'To many attempts'){
          this.error = 'Суточный лимит запросов восстановления пароля превышен. Обратитесь за помощью к администратору сайта: festival@gto.ru'
        }
      }
    }
  }
};
</script>
