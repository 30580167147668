<template>
  <v-navigation-drawer
    stateless
    :value="isDesktop || (isMobile && show)"
    :mini-variant="miniVariant"
    mini-variant-width="59"
    @transitionend="handler"
    fixed
    :temporary="isMobile"
    v-click-outside="outside"
  >
    <v-list>
      <v-list-item>
        <v-img
          contain
          :src="miniVariant ? `${publicPath}images/logo.svg` : `${publicPath}images/logo-wide.svg` "
        ></v-img>
      </v-list-item>
    </v-list>

    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap"><strong>{{ miniVariant ? shortName : fullName }}</strong>
            
          </v-list-item-title>
          <router-link :to="{ name: 'profile.edit'}" class="edit-profile-btn">Ред. профиль</router-link>
          <v-list-item-subtitle class="mt-1">{{ role }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-tooltip bottom content-class="tooltip-gto">
            <template #activator="{on, attrs}">
              <v-icon v-bind="attrs" v-on="on" @click="logout">mdi-logout</v-icon>
            </template>
            Выйти
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list
        nav
        dense
    >
      <v-list-item-group
          v-model="selectedItem"
          color="primary"
      >
        <v-list-item
          v-for="(item, i) in menuItems"
          @click="show = false"
          :to="{ name: item.name}"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon :style="'width:30px'" v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <div class="protocols-generations" v-permission="'festivals.index'">
        <festival-protocols-generations-component></festival-protocols-generations-component>
      </div>
      <div class="svod-status" v-permission="'svod.index'" v-if="(showProgressBar || isSvodProcessed) && !miniVariant">
        <svod-progress-component :navigation="true"></svod-progress-component>
      </div>
      <v-tooltip top content-class="tooltip-gto">
        <template #activator="{on, attrs}">
          <div v-bind="attrs" v-on="on" class="hide-button" @click="miniVariantToggle">
            <v-icon small v-text="chevronSide"></v-icon>
          </div>
        </template>
        Свернуть
      </v-tooltip>

    </template>
  </v-navigation-drawer>
</template>


<script>
import {menu} from '@/constants/menu';
import {api} from '@/api';
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';
import SvodProgressComponent from '@/views/Svod/components/svod-progress-component';
import FestivalProtocolsGenerationsComponent
  from '@/views/Festival/components/festival-protocols-generations-component';

export default {
  name: 'NavLeft',
  components: {FestivalProtocolsGenerationsComponent, SvodProgressComponent},
  data() {
    return {
      selectedItem: 0,
      transitionendMiniVariant: true,

      publicPath: process.env.BASE_URL,
      show: false,
      opened: false,
    }
  },
  created() {
    if (this.isLoggedIn && this.$gates.hasPermission('svod.index')){
      this.getActiveSvod();
    }

    if (!this.user){
      this.$router.push({name: 'auth.login', query: {redirect_url: this.$route.path}})
    }

  },
  computed: {
    ...mapState('app', ['miniVariant']),
    ...mapGetters('app', ['isDesktop', 'isMobile']),

    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isLoggedIn']),

    ...mapState('svod', ['activeSvod']),
    ...mapGetters('svod', ['showProgressBar', 'isSvodProcessed']),
    chevronSide() {
      return `fas fa-chevron-${this.miniVariant ? 'right' : 'left'}`;
    },
    fullName() {
      return this.user.profile.name + ' ' + this.user.profile.surname;
    },
    shortName() {
      return this.user.profile.name.charAt(0) + ' ' + this.user.profile.surname.charAt(0);
    },
    role() {
      return this.user.role.title;
    },
    menuItems() {
      return menu.filter((item) => {
        return this.$gates.hasPermission(item.name)
      })
    }
  },
  methods: {
    ...mapMutations('app', ['set_variant']),
    ...mapActions('svod', ['getActiveSvod']),
    handler() {
      this.transitionendMiniVariant = !this.miniVariant;
      this.opened = !this.opened
    },
    miniVariantToggle() {
      if (this.isDesktop) {
        this.set_variant(!this.miniVariant);
      } else {
        this.show = !this.show;
      }
    },
    outside(e) {
      if (e.target.classList.contains('v-overlay__scrim')) {
        this.show = false;
      }
    },
    logout() {
      api.auth.logout()
        //.catch(errorHandler)
        .finally(() => {
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
          this.$router.push({name: 'auth.login', query: {redirect_url: this.$route.path}})
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.edit-profile-btn {
  margin-top: 6px;
  font-weight: 500;
  color: #686868;
}

::v-deep {
  .v-list-item__title {
    white-space: normal !important;
  }
  
  .v-list-item__icon {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
</style>
