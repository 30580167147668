<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-card class="mx-auto">
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card-title>
            {{ title }}
          </v-card-title>

          <v-card-text>
            <v-row no-gutters class="mt-3">
              <v-col cols="12" md="4">
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-text-field
                      color="primary"
                      class="mr-md-2 mb-2 mb-md-0"
                      v-model="form.surname"
                      filled
                      label="Фамилия"
                      hide-details
                      :error-messages="errors"
                      :disabled="loading"
                      dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-text-field
                      color="primary"
                      class="mr-md-2 mb-2 mb-md-0"
                      v-model="form.name"
                      filled
                      label="Имя"
                      hide-details
                      :error-messages="errors"
                      :disabled="loading"
                      dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                    v-slot="{ errors }"

                >
                  <v-text-field
                      color="primary"
                      v-model="form.patronymic"
                      filled
                      class="mb-2 mb-md-0"
                      label="Отчество"
                      hide-details
                      :error-messages="errors"
                      :disabled="loading"
                      dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col cols="12" md="6">
                <ValidationProvider
                    v-slot="{ errors }"

                >
                  <v-text-field
                      color="primary"
                      class="mr-md-2 mb-2 mb-md-0"
                      v-model="form.uin"
                      filled
                      label="УИН"
                      hide-details
                      :error-messages="errors"
                      :disabled="loading"
                      dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                    v-slot="{ errors }"
                    rules="email"
                >
                  <v-text-field
                      color="primary"
                      class="mb-2 mb-md-0"
                      v-model="form.email"
                      filled
                      label="Email"
                      hide-details
                      :error-messages="errors"
                      :disabled="loading"
                      dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>

            </v-row>

            <v-row no-gutters class="mt-3">
              <v-col cols="12" md="4">
                <ValidationProvider
                    v-slot="{ errors }"
                >
                  <v-autocomplete
                      v-model="form.country"
                      :items="countriesList"
                      label="Страна"
                      filled
                      item-value="id"
                      item-text="name"
                      return-object
                      hide-details
                      :error-messages="errors"
                      dense
                      :readonly="form.loading"
                      :disabled="loading"
                      class="mr-md-2 mb-2 mb-md-0"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                    v-slot="{ errors }"
                >
                  <v-autocomplete
                      v-model="form.region"
                      :items="regionsList"
                      label="Регион"
                      filled
                      item-value="id"
                      item-text="fullname"
                      return-object
                      hide-details
                      :error-messages="errors"
                      dense
                      :disabled="loading || canSetRegion"
                      class="mr-md-2 mb-2 mb-md-0"
                  >

                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                    v-slot="{ errors }"
                >
                  <v-autocomplete
                      v-model="form.team"
                      :items="teamsList"
                      label="Команда"
                      filled
                      item-value="id"
                      item-text="teamable.name"
                      return-object
                      hide-details
                      :error-messages="errors"
                      dense
                      :disabled="loading || !canSetTeams"
                      class="mb-2 mb-md-0"
                  >
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-3">
              <v-col cols="12" md="4" class="pr-0 pr-md-3 mb-3 mb-md-0">
                <div style="min-height: 115px; height: 100%">
                <image-upload-component :use-crop="true"
                                        :label="'Фотография'"
                                        ref="imageUploader"
                                        :disabled="loading"
                                        :file-data="form.photo"
                                        :file.sync="form.photo"></image-upload-component>
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <v-row class="mb-0">
                  <v-col cols="12" class="pb-0">
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                    >
                      <v-select
                          :items="genders.list"
                          filled
                          v-model="form.gender"
                          item-text="text"
                          item-value="id"
                          label="Пол"
                          hide-details
                          dense
                          return-object
                          :disabled="loading"
                          :error-messages="errors"
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12">
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"

                    >
                      <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >  <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="birthdayFormatted"
                            label="Дата рождения"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            filled
                            v-bind="attrs"
                            v-on="on"
                            dense
                            :disabled="loading"
                            hide-details
                        ></v-text-field>
                      </template>
                        <v-date-picker
                            v-model="form.birthday"
                            :error-messages="errors"
                            :max="maxDate"
                            :min="minDate"
                            active-picker="YEAR"
                        ></v-date-picker>
                      </v-menu>
                    </ValidationProvider>
                  </v-col>
                </v-row>

              </v-col>

            </v-row>

            <v-row no-gutters v-if="populateWith && populateWith.age_category">
              <v-col class="mt-3">Назначенная возрастная группа: {{ populateWith.age_category.description }}
              </v-col>
            </v-row>

          </v-card-text>


          <v-card-actions class="pa-4">
            <v-btn
                color="primary"
                :disabled="invalid || loading"
                @click="submit()"
                :loading="loading"
            >
              {{ buttonLabel }}
            </v-btn>

            <v-btn
                color="primary"
                outlined
                text
                @click="dialog = false"
                v-show="!loading"
            >
              Отменить
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');

import {mapState, mapActions} from 'vuex';

import {api} from '@/api';
import ImageUploadComponent from '@/components/common/image-upload-component';

export default {
  name: 'create-edit-member-component',
  components: {
    ImageUploadComponent,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    festival: {
      type: Object,
      default: () => ({})
    },
    populateWith: {
      type: Object,
      default: () => ({})
    },
    countriesList: {
      type: Array,
      default: () => ([])
    },
    regionsList: {
      type: Array,
      default: () => ([])
    },
    teamsList: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    form: {
      surname: null,
      name: null,
      patronymic: null,
      uin: null,
      email: null,
      gender: null,
      birthday: null,
      country: null,
      region: null,
      team: null,
      photo: null,
      id: null
    },
    genders: {
      list: [{id: 1, text: 'Мужской'}, {id: 2, text: 'Женский'}]
    },
  }),
  watch: {
    populateWith() {
      Object.assign(this.$data.form, this.$options.data().form)

      if (this.isEdit){
        this.form = this.populateWith;
      }

    },

  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('roles', ['roles']),
    isEdit() {
      return (this.populateWith && Object.keys(this.populateWith).length > 0 && this.populateWith.constructor === Object)
    },
    title() {
      return this.isEdit ? 'Редактировать участника' : 'Добавить участника'
    },
    buttonLabel() {
      return this.isEdit ? 'Сохранить' : 'Добавить'
    },
    maxDate() {
      return new Date(new Date(this.festival.settings.age_level_verification_date).setFullYear(new Date().getFullYear() - 6)).toISOString().substr(0, 10);
    },
    minDate() {
      return new Date(new Date(this.festival.settings.age_level_verification_date).setFullYear(new Date().getFullYear() - 100)).toISOString().substr(0, 10);
    },
    canSetRegion() {
      return (!this.form.country || (this.form.country && this.form.country.id !== 180))
    },
    canSetTeams() {
      if (this.festival && this.festival.settings) {
        return (this.festival.settings.credit_type === 'team_and_personal');
      }
      return false;
    },
    birthdayFormatted() {
      return this.formatDate(this.form.birthday)
    },
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    submit() {
      this.$refs.observer.validate().then(async (result) => {
        if (result) {
          this.loading = true;
          try {
            if (this.isEdit){
              await api.festival.members.update(this.$route.params.id, this.form.id, this.form )
              this.notify('Участник изменен')
            } else {
              await api.festival.members.store(this.$route.params.id,  this.form )
              this.notify('Участник добавлен')
            }

            Object.assign(this.$data.form, this.$options.data().form)

            this.dialog = false;
            this.$emit('saved');
          } catch (err) {
            this.error(err.response.data.error);
          }

          this.loading = false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
